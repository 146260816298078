.new-meeting {
    overflow-y: auto;
    max-height: 85vh;

    .locations {
        background-color: var(--background-color-1, #F7F7F7);
        padding: 16px;
        margin-bottom: 25px;
        font-size: 14px;

        &.invalid:not(.only-custom) {
            border: solid 1px red;
        }

        &.only-custom {
            background-color: transparent;
            padding: 0px;
            margin-bottom: 15px;
        }

        .form-control.ng-invalid {
            border-bottom-color: rgb(222, 224, 225);
        }

        h4 {
            font-size: 14px;
            font-weight: bold;
            margin-top: 0;
        }

        >p:last-child {
            margin-bottom: 0;
        }

        .form-group {
            margin-bottom: 0;

            input {
                background-color: transparent;
            }
        }

        .title .capacity {
            color: var(--foreground-color-2, #7D8082);
            font-weight: normal;
        }

        .subtitle {
            color: var(--foreground-color-2, #7D8082);
        }

        .error {
            padding-left: 0;
            margin-bottom: 16px;
        }

    }

    .rooms {
        margin-bottom: 24px;

        .list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 12px;
        }
    }

    .more,
    .less {
        display: inline-block;
        margin-top: 8px;

        &:hover {
            text-decoration: none;
        }

        i {
            margin-left: 8px;
        }
    }

    .room {
        background-color: var(--background-color-3, #FFFFFF);
        border: 1px solid var(--background-color-2, #DDDDDD);
        border-radius: 4px;
        padding: 8px 12px;

        &.selected {
            border-color: var(--contrast-color);
            box-shadow: 0 0 0 1px var(--contrast-color);
        }

        .title {
            font-weight: bold;
        }

    }

    .date-picker-wrapper {
        .form-control.date {
            cursor: pointer;
        }
    }

    .time-slots {
        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            margin-bottom: 12px;
        }

        margin-bottom: 16px;
    }
}

.dark-mode {
    .new-meeting {
        .locations {
            .error {
                color: var(--foreground-color-1, #7D8082);
            }
        }
    }
}

.form-actions {
    button:disabled {
        opacity: 0.4;
    }
}

/*
  to avoid action buttons being hidden by the address bar on mobile
  see https://spotme.atlassian.net/browse/EP-18754
 */
@media (max-width: $screen-sm-max) {
    .new-meeting {
        .form-actions {
            margin-bottom: 70px;
        }
    }
}
