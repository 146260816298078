.nav-panels {
	margin: 0 auto;
	max-width: $main-content-width;

	.nav-panel:not(:last-child) {
		display: none;
	}

	.nav-panel {

		.nav-content {
			padding-bottom: 16px;
		}

		.compliance-header,
		.compliance-footer {
			padding: 12px;
			border-radius: 4px;
			border-style: solid;

			p {
				margin: 0;
			}
		}

		.compliance-header {
			margin-bottom: 16px;

            a {
                color: var(--header-link-color);
            }
		}

		.compliance-footer {
			position: relative;
			box-sizing: border-box;

            a {
                color: var(--footer-link-color);
            }
		}
	}
}

nav .container-fluid.no-gutters {
	padding: 0;
}

nav .container-fluid.no-gutters .row {
	// https://getbootstrap.com/docs/4.4/layout/grid/#no-gutters

	margin-right: 0;
	margin-left: 0;

	>.col,
	>[class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}

	.nav-sidebar {
		padding-left: 15px;
	}
}

.nav-fstg .nav-content {
	height: 100%;
	min-height: calc(100vh - #{$body-padding * 3});
}

.nav-fstg .nav-content:has(.calendar) {
	min-height: calc(100vh - #{$body-padding * 2});
}

.nav-fstg .nav-content:has(.map) {
	min-height: calc(100vh - #{$body-padding});
}

.nav-fstg nav-header+.nav-content,
.nav-note .nav-content {
	height: 100%;
	min-height: calc(100vh - #{$body-padding * 2} - #{$nav-header-height});
}

sheet .nav-content {
	border: 0;
	border-radius: 0;
	padding: 0 15px;
	background-color: inherit;
}

.modal.modal-sheet .nav-content:has(.panel-form) {
	padding: 0;
	min-height: fit-content;
	height: fit-content;
}

.nav-header {
	background-position: 50%;
	background-size: cover;
	color: $spotme-light-grey;
	font-weight: bold;
	text-align: left;
	margin-bottom: 10px;

	.action-button {
		padding: 12px 15px 7px 15px;
		font-size: 18px;
	}
}

.chip-wrapper {
	display: none;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	z-index: 1;

	.chip {
		max-width: 100%;
		border-radius: 12px;
		line-height: 1;
		padding: 2px 8px;
		margin-top: 8px;
		font-size: 12px;
		font-weight: bold;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.grid-layout {
	$tiles-gutter: 32px;

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

	.tile {
		margin: math.div($tiles-gutter, 2) auto;
		width: 100%;
		padding: 8px;
	}

	.media-left,
	.media-body {
		display: block;
		text-align: center;
	}

	.row-person {
		.media-left {
			&::after {
				display: none;
			}
		}
	}

	.media-left {
		display: block;
		height: auto;
		position: relative;
		padding-right: 0;
		margin-bottom: 8px;

		&::after {
			content: ' ';
			display: block;
			width: 1px;
			height: 1px;
			padding-bottom: 100%;
		}

		.img-circle {
			border-radius: 5px;
		}
	}

	.media-object {
		.initials {
			font-size: 3rem;
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
		}
	}

	.chip-wrapper {
		display: flex;
	}

	.nav-list-item {
		border-bottom: none;
		padding: 0;
	}

	.nav-list-item-title,
	.nav-list-item-subtitle,
	.nav-list-item-subsubtitle {
		max-height: none;
	}
}

.explorer-11 {
	.grid-layout {
		display: flex;
		flex-wrap: wrap;

		.tile {
			width: 191px;
		}
	}
}

.current_page_nav_home_feed {
	blocks-list-nav {
		max-width: $main-content-width;
	}
}

@media (max-width: $screen-xs-max) {
	.grid-layout {
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	}

	.nav-fstg .nav-content {
		min-height: calc(100vh - #{$nav-header-height});
	}

	.nav-fstg nav-header+.nav-content,
	.nav-note .nav-content {
		// 48px in header
		min-height: calc(100vh - #{$nav-header-height} - 48px);
	}
}
