// General
$image-path: '../graphics';
$fonts-path: '../graphics';

//== Scaffolding
$base-margin: 16px;
$base-padding: 16px;
$body-bg: #f7f7f7;
$text-color: #000;
$body-padding: 60px;
$main-content-width: 1200px;

$nav-background-color: $body-bg;
$nav-content-background-color: $body-bg;
$boxed-content-background-color: #f3f3f3;
$boxed-content-borders-color: #ddd;
$placeholder-color: #ddd;
$std-border-radius: 4px;

//== Typography

$font-family-base: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base: 14px;
$font-size-large:         ceil(($font-size-base * 1.25));
$font-size-small:         ceil(($font-size-base * 0.85));

$font-size-h1: calculate-rem( floor(($font-size-base * 2.3)) );
$font-size-h2: calculate-rem( floor(($font-size-base * 2.1)) );
$font-size-h3: calculate-rem( ceil(($font-size-base * 1.3)) );
$font-size-h4: calculate-rem( $font-size-base );
$font-size-h5: calculate-rem( ceil(($font-size-base * 0.85)) );
$font-size-h6: calculate-rem( ceil(($font-size-base * 0.75)) );

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #2c3e50;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$brand-primary:         #fb4f15;
$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

//== Type
$hr-border:                   $gray-lighter;
$text-muted:                  $gray-light;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;

$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;

$screen-md:                  992px;
$screen-md-min:              $screen-md;

$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;

$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

//== Components

$slick-dot-size: 36px;

// Navs
$nav-header-height: 45px;
$nav-bar-height: 44px;

$nav-first-pane-width-sm: .25;
$nav-second-pane-width-sm: .33;
$nav-third-pane-left-offset-sm: $nav-first-pane-width-sm + $nav-second-pane-width-sm;
$nav-third-pane-width-sm: 1 - $nav-third-pane-left-offset-sm;

$nav-first-pane-width-md: .16;
$nav-second-pane-width-md: .34;
$nav-third-pane-left-offset-md: $nav-first-pane-width-md + $nav-second-pane-width-md;
$nav-third-pane-width-md: 1 - $nav-third-pane-left-offset-md;


// Spotman
$nav-spotman-text-color: #fff !default;

// In-App-Notification
$in-app-notification-z-index: 11112;
$in-app-notification-width: 288px;
$in-app-notification-icon-size: 42px;


// Q&A
$qna-sidebar-width: 282px;


$base-unit: 8px;
$half-unit: math.div($base-unit, 2);
