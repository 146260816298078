@import 'frontend-real-time-com-toolkit/dist/frontend-real-time-com-toolkit';

.video-call-v2 {
    height: 100%;

    [class^="icon-"], [class*=" icon-"] {
        // ACS icon fonts conflict with entypo ones, so we need to force the font.
        // See: https://github.com/Spotme/frontloader-common/blob/master/vendor/entypo/entypo.css#L11
        font-family: FabricMDL2Icons !important;
    }
}
